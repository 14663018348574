// https://cookieconsent.orestbida.com/essential/getting-started.html#vue
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import type { Plugin } from "vue";

let initialized = false

const vueCookieConsent: Plugin = {
  install: (app, pluginConfig) => {

    app.config.globalProperties.$CookieConsent = CookieConsent;
    app.config.globalProperties.$CookieConsent.run(pluginConfig);

    if(initialized) return
    initialized = true


    setTimeout(app.config.globalProperties.$CookieConsent.show, 3000);
    
  },
};

export default vueCookieConsent;
