export const cookiesTranslations = {
    "en": {
        consentModal: {
            title: "Cookie Preferences",
            description: "We use cookies to enhance your browsing experience and analyze site traffic. Please choose your preferences below.",
            acceptAllBtn: "Accept All Cookies",
            acceptNecessaryBtn: "Accept Only Necessary Cookies",
            showPreferencesBtn: "Manage Preferences",
        },
        preferencesModal: {
            title: "Cookie Settings",
            acceptAllBtn: "Accept All",
            acceptNecessaryBtn: "Accept Only Necessary",
            savePreferencesBtn: "Save Preferences",
            closeIconLabel: "Close",
            sections: [
                {
                    title: "Your Privacy Matters",
                    description: "We respect your right to control your data. You can manage your cookie preferences here.",
                },
                {
                    title: "Essential Cookies",
                    description:
                        "These cookies are crucial for the website's functionality and cannot be disabled. They don't store any personally identifiable information.",
                    linkedCategory: "necessary",
                },
                {
                    title: "Analytics Cookies",
                    description:
                        "These cookies help us understand how visitors interact with our website. The data is anonymized and used to improve your experience.",
                    linkedCategory: "analytics",
                },
                {
                    title: "Additional Information",
                    description:
                        'For more details about our cookie policy and your choices, please <a href="#contact-page">contact us</a>.',
                },
            ],
        },
    },
    "ru": {
        consentModal: {
            title: "Настройки файлов cookie",
            description: "Мы используем файлы cookie для улучшения вашего опыта просмотра и анализа трафика сайта. Пожалуйста, выберите ваши предпочтения ниже.",
            acceptAllBtn: "Принять все файлы cookie",
            acceptNecessaryBtn: "Принять только необходимые файлы cookie",
            showPreferencesBtn: "Управление настройками",
        },
        preferencesModal: {
            title: "Настройки файлов cookie",
            acceptAllBtn: "Принять все",
            acceptNecessaryBtn: "Принять только необходимые",
            savePreferencesBtn: "Сохранить настройки",
            closeIconLabel: "Закрыть",
            sections: [
                {
                    title: "Ваша конфиденциальность важна",
                    description: "Мы уважаем ваше право контролировать свои данные. Здесь вы можете управлять настройками файлов cookie.",
                },
                {
                    title: "Необходимые файлы cookie",
                    description:
                        "Эти файлы cookie необходимы для функционирования веб-сайта и не могут быть отключены. Они не хранят личную информацию.",
                    linkedCategory: "necessary",
                },
                {
                    title: "Аналитические файлы cookie",
                    description:
                        "Эти файлы cookie помогают нам понять, как посетители взаимодействуют с нашим веб-сайтом. Данные анонимизированы и используются для улучшения вашего опыта.",
                    linkedCategory: "analytics",
                },
                {
                    title: "Дополнительная информация",
                    description:
                        'Для получения дополнительной информации о нашей политике в отношении файлов cookie и ваших возможностях, пожалуйста, <a href="#contact-page">свяжитесь с нами</a>.',
                },
            ],
        },
    },
    "de": {
        "consentModal": {
            "title": "Cookie-Einstellungen",
            "description": "Wir verwenden Cookies, um Ihr Browsing-Erlebnis zu verbessern und den Website-Verkehr zu analysieren. Bitte wählen Sie unten Ihre Präferenzen.",
            "acceptAllBtn": "Alle Cookies akzeptieren",
            "acceptNecessaryBtn": "Nur notwendige Cookies akzeptieren",
            "showPreferencesBtn": "Einstellungen verwalten"
        },
        "preferencesModal": {
            "title": "Cookie-Einstellungen",
            "acceptAllBtn": "Alle akzeptieren",
            "acceptNecessaryBtn": "Nur Notwendige akzeptieren",
            "savePreferencesBtn": "Einstellungen speichern",
            "closeIconLabel": "Schließen",
            "sections": [
                {
                    "title": "Ihre Privatsphäre ist wichtig",
                    "description": "Wir respektieren Ihr Recht, Ihre Daten zu kontrollieren. Hier können Sie Ihre Cookie-Einstellungen verwalten."
                },
                {
                    "title": "Essentielle Cookies",
                    "description": "Diese Cookies sind für die Funktionalität der Website unerlässlich und können nicht deaktiviert werden. Sie speichern keine personenbezogenen Daten.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Analyse-Cookies",
                    "description": "Diese Cookies helfen uns zu verstehen, wie Besucher mit unserer Website interagieren. Die Daten werden anonymisiert und zur Verbesserung Ihres Erlebnisses verwendet.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Zusätzliche Informationen",
                    "description": 'Für weitere Details zu unserer Cookie-Richtlinie und Ihren Wahlmöglichkeiten <a href="#contact-page">kontaktieren Sie uns</a> bitte.'
                }
            ]
        }
    },
    "es": {
        "consentModal": {
            "title": "Preferencias de Cookies",
            "description": "Utilizamos cookies para mejorar su experiencia de navegación y analizar el tráfico del sitio. Por favor, elija sus preferencias a continuación.",
            "acceptAllBtn": "Aceptar todas las Cookies",
            "acceptNecessaryBtn": "Aceptar solo las Cookies necesarias",
            "showPreferencesBtn": "Gestionar Preferencias"
        },
        "preferencesModal": {
            "title": "Configuración de Cookies",
            "acceptAllBtn": "Aceptar Todas",
            "acceptNecessaryBtn": "Aceptar solo las Necesarias",
            "savePreferencesBtn": "Guardar Preferencias",
            "closeIconLabel": "Cerrar",
            "sections": [
                {
                    "title": "Su Privacidad es Importante",
                    "description": "Respetamos su derecho a controlar sus datos. Puede gestionar sus preferencias de cookies aquí."
                },
                {
                    "title": "Cookies Esenciales",
                    "description": "Estas cookies son cruciales para el funcionamiento del sitio web y no pueden ser desactivadas. No almacenan ninguna información personal identificable.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Cookies Analíticas",
                    "description": "Estas cookies nos ayudan a entender cómo los visitantes interactúan con nuestro sitio web. Los datos son anonimizados y se utilizan para mejorar su experiencia.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Información Adicional",
                    "description": 'Para más detalles sobre nuestra política de cookies y sus opciones, por favor <a href="#contact-page">contáctenos</a>.'
                }
            ]
        }
    },
    "fr": {
        "consentModal": {
            "title": "Préférences de Cookies",
            "description": "Nous utilisons des cookies pour améliorer votre expérience de navigation et analyser le trafic du site. Veuillez choisir vos préférences ci-dessous.",
            "acceptAllBtn": "Accepter tous les Cookies",
            "acceptNecessaryBtn": "Accepter uniquement les Cookies nécessaires",
            "showPreferencesBtn": "Gérer les Préférences"
        },
        "preferencesModal": {
            "title": "Paramètres des Cookies",
            "acceptAllBtn": "Tout Accepter",
            "acceptNecessaryBtn": "Accepter uniquement les Nécessaires",
            "savePreferencesBtn": "Enregistrer les Préférences",
            "closeIconLabel": "Fermer",
            "sections": [
                {
                    "title": "Votre Vie Privée est Importante",
                    "description": "Nous respectons votre droit de contrôler vos données. Vous pouvez gérer vos préférences de cookies ici."
                },
                {
                    "title": "Cookies Essentiels",
                    "description": "Ces cookies sont cruciaux pour le fonctionnement du site web et ne peuvent pas être désactivés. Ils ne stockent aucune information personnelle identifiable.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Cookies Analytiques",
                    "description": "Ces cookies nous aident à comprendre comment les visiteurs interagissent avec notre site web. Les données sont anonymisées et utilisées pour améliorer votre expérience.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Informations Supplémentaires",
                    "description": 'Pour plus de détails sur notre politique de cookies et vos choix, veuillez <a href="#contact-page">nous contacter</a>.'
                }
            ]
        }
    },
    "it": {
        "consentModal": {
            "title": "Preferenze dei Cookie",
            "description": "Utilizziamo i cookie per migliorare la tua esperienza di navigazione e analizzare il traffico del sito. Si prega di scegliere le proprie preferenze di seguito.",
            "acceptAllBtn": "Accetta tutti i Cookie",
            "acceptNecessaryBtn": "Accetta solo i Cookie necessari",
            "showPreferencesBtn": "Gestisci Preferenze"
        },
        "preferencesModal": {
            "title": "Impostazioni dei Cookie",
            "acceptAllBtn": "Accetta Tutti",
            "acceptNecessaryBtn": "Accetta solo i Necessari",
            "savePreferencesBtn": "Salva Preferenze",
            "closeIconLabel": "Chiudi",
            "sections": [
                {
                    "title": "La Tua Privacy è Importante",
                    "description": "Rispettiamo il tuo diritto di controllare i tuoi dati. Puoi gestire le tue preferenze dei cookie qui."
                },
                {
                    "title": "Cookie Essenziali",
                    "description": "Questi cookie sono cruciali per il funzionamento del sito web e non possono essere disattivati. Non memorizzano alcuna informazione personale identificabile.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Cookie Analitici",
                    "description": "Questi cookie ci aiutano a capire come i visitatori interagiscono con il nostro sito web. I dati sono anonimizzati e utilizzati per migliorare la tua esperienza.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Informazioni Aggiuntive",
                    "description": 'Per maggiori dettagli sulla nostra politica dei cookie e le tue scelte, si prega di <a href="#contact-page">contattarci</a>.'
                }
            ]
        }
    },
    "tr": {
        "consentModal": {
            "title": "Çerez Tercihleri",
            "description": "Tarama deneyiminizi geliştirmek ve site trafiğini analiz etmek için çerezleri kullanıyoruz. Lütfen aşağıdan tercihlerinizi seçin.",
            "acceptAllBtn": "Tüm Çerezleri Kabul Et",
            "acceptNecessaryBtn": "Sadece Gerekli Çerezleri Kabul Et",
            "showPreferencesBtn": "Tercihleri Yönet"
        },
        "preferencesModal": {
            "title": "Çerez Ayarları",
            "acceptAllBtn": "Tümünü Kabul Et",
            "acceptNecessaryBtn": "Sadece Gerekli Olanları Kabul Et",
            "savePreferencesBtn": "Tercihleri Kaydet",
            "closeIconLabel": "Kapat",
            "sections": [
                {
                    "title": "Gizliliğiniz Önemlidir",
                    "description": "Verilerinizi kontrol etme hakkınıza saygı duyuyoruz. Çerez tercihlerinizi burada yönetebilirsiniz."
                },
                {
                    "title": "Gerekli Çerezler",
                    "description": "Bu çerezler web sitesinin işlevselliği için çok önemlidir ve devre dışı bırakılamazlar. Kişisel olarak tanımlanabilir herhangi bir bilgi saklamazlar.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Analitik Çerezler",
                    "description": "Bu çerezler, ziyaretçilerin web sitemizle nasıl etkileşimde bulunduğunu anlamamıza yardımcı olur. Veriler anonimleştirilir ve deneyiminizi iyileştirmek için kullanılır.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Ek Bilgi",
                    "description": 'Çerez politikamız ve seçenekleriniz hakkında daha fazla ayrıntı için lütfen <a href="#contact-page">bizimle iletişime geçin</a>.'
                }
            ]
        }
    },
    "zh": {
        "consentModal": {
            "title": "Cookie 偏好设置",
            "description": "我们使用 Cookie 来改善您的浏览体验并分析网站流量。请在下方选择您的偏好设置。",
            "acceptAllBtn": "接受所有 Cookie",
            "acceptNecessaryBtn": "仅接受必要的 Cookie",
            "showPreferencesBtn": "管理偏好设置"
        },
        "preferencesModal": {
            "title": "Cookie 设置",
            "acceptAllBtn": "全部接受",
            "acceptNecessaryBtn": "仅接受必要的",
            "savePreferencesBtn": "保存偏好设置",
            "closeIconLabel": "关闭",
            "sections": [
                {
                    "title": "您的隐私很重要",
                    "description": "我们尊重您控制自己数据的权利。您可以在此管理您的 Cookie 偏好设置。"
                },
                {
                    "title": "必要的 Cookie",
                    "description": "这些 Cookie 对网站的功能至关重要，不能被禁用。它们不存储任何可识别个人身份的信息。",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "分析 Cookie",
                    "description": "这些 Cookie 帮助我们了解访问者如何与我们的网站互动。数据是匿名的，用于改善您的体验。",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "更多信息",
                    "description": '有关我们的 Cookie 政策和您的选择的更多详细信息，请<a href="#contact-page">联系我们</a>。'
                }
            ]
        }
    },
    "pt": {
        "consentModal": {
            "title": "Preferências de Cookies",
            "description": "Utilizamos cookies para melhorar sua experiência de navegação e analisar o tráfego do site. Por favor, escolha suas preferências abaixo.",
            "acceptAllBtn": "Aceitar Todos os Cookies",
            "acceptNecessaryBtn": "Aceitar Apenas Cookies Necessários",
            "showPreferencesBtn": "Gerenciar Preferências"
        },
        "preferencesModal": {
            "title": "Configurações de Cookies",
            "acceptAllBtn": "Aceitar Todos",
            "acceptNecessaryBtn": "Aceitar Apenas Necessários",
            "savePreferencesBtn": "Salvar Preferências",
            "closeIconLabel": "Fechar",
            "sections": [
                {
                    "title": "Sua Privacidade é Importante",
                    "description": "Respeitamos seu direito de controlar seus dados. Você pode gerenciar suas preferências de cookies aqui."
                },
                {
                    "title": "Cookies Essenciais",
                    "description": "Estes cookies são cruciais para o funcionamento do site e não podem ser desativados. Eles não armazenam nenhuma informação pessoal identificável.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "Cookies Analíticos",
                    "description": "Estes cookies nos ajudam a entender como os visitantes interagem com nosso site. Os dados são anonimizados e usados para melhorar sua experiência.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "Informações Adicionais",
                    "description": 'Para mais detalhes sobre nossa política de cookies e suas escolhas, por favor <a href="#contact-page">entre em contato conosco</a>.'
                }
            ]
        }
    },
    "ko": {
        "consentModal": {
            "title": "쿠키 설정",
            "description": "당사는 귀하의 브라우징 경험을 향상시키고 사이트 트래픽을 분석하기 위해 쿠키를 사용합니다. 아래에서 귀하의 선호 사항을 선택해 주세요.",
            "acceptAllBtn": "모든 쿠키 수락",
            "acceptNecessaryBtn": "필수 쿠키만 수락",
            "showPreferencesBtn": "설정 관리"
        },
        "preferencesModal": {
            "title": "쿠키 설정",
            "acceptAllBtn": "모두 수락",
            "acceptNecessaryBtn": "필수만 수락",
            "savePreferencesBtn": "설정 저장",
            "closeIconLabel": "닫기",
            "sections": [
                {
                    "title": "귀하의 개인정보 보호가 중요합니다",
                    "description": "당사는 귀하의 데이터 제어 권리를 존중합니다. 여기에서 쿠키 설정을 관리할 수 있습니다."
                },
                {
                    "title": "필수 쿠키",
                    "description": "이 쿠키들은 웹사이트의 기능에 필수적이며 비활성화할 수 없습니다. 개인 식별 정보를 저장하지 않습니다.",
                    "linkedCategory": "necessary"
                },
                {
                    "title": "분석 쿠키",
                    "description": "이 쿠키들은 방문자가 당사 웹사이트와 어떻게 상호작용하는지 이해하는 데 도움을 줍니다. 데이터는 익명화되며 귀하의 경험을 개선하는 데 사용됩니다.",
                    "linkedCategory": "analytics"
                },
                {
                    "title": "추가 정보",
                    "description": '당사의 쿠키 정책 및 귀하의 선택에 대한 자세한 내용은 <a href="#contact-page">문의하기</a>를 참조하세요.'
                }
            ]
        }
    }
}