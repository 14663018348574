import type {App} from "vue"
import {createPinia} from "pinia"
import vueCookieConsent from "./plugins/vue-cookie-consent"
import {geti18n} from "@/i18n.ts";
import {cookiesTranslations} from "@/i18n/cookiesTranslations.ts";

// for a bug: https://github.com/intlify/vue-i18n/issues/1569
declare const __VUE_PROD_DEVTOOLS__ = false;

export default (app: App) => {

  // for a bug: https://github.com/intlify/vue-i18n/issues/1569
  let prevNodeEnv;
  if (typeof process !== "undefined"){
    prevNodeEnv = process.env.NODE_ENV;
    process.env.NODE_ENV = "false"
  }

  // process.env.NODE_ENV = "false"
  const i18n = geti18n();
  const pinia = createPinia();
  app.use(pinia);
  app.use(i18n)

  if (typeof process !== "undefined"){
    process.env.NODE_ENV = prevNodeEnv
  }

  if (typeof window !== "undefined") app.use(vueCookieConsent, {
      autoShow: false, // <== delay the modal so other animations are the focus point at first
      guiOptions: {
        consentModal: {
          layout: "box wide",
          position: "bottom left",
          flipButtons: false,
          equalWeightButtons: true,
        },
        preferencesModal: {
          layout: "box",
          // position: 'left right',
          flipButtons: false,
          equalWeightButtons: true,
        },
      },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

    language: {
      default: "en",
      autoDetect: 'document',
      translations: {
        ...cookiesTranslations
      },
    },
  });
};
